<template>
  <div class="Orderdetails_area" :class="{'fix': isScroll, 'is_stuck text-right': device==='mobile'}">
    <div class="Orderdetails_top">
      <h3>
        <span>
          <img src="/assets/sabre-img/flightbookingicon1.png" alt="flight-booking-icon" />
        </span>{{$t("sabre.booking.order-detail-box.order-detail")}}
      </h3>
    </div>
    <div class="Orderdetails_body">

      <order-detail-top />

      <order-detail-mini-box />

      <general-terms-area />

      <total-box />

    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import $ from 'jquery';

export default {
  name: 'order-detail-area',
  components: {
    OrderDetailTop: () => import('./orderDetailTop'),
    OrderDetailMiniBox: () => import('./orderDetailMiniBox'),
    GeneralTermsArea: () => import('./generalTermsArea'),
    TotalBox: () => import('./totalBox'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
    }),
  },
  data() {
    return {
      isScroll: false,
    };
  },
  mounted() {
    document.querySelector('.st-content').removeEventListener('scroll', this.onScroll);
    document.querySelector('.st-content').addEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.isScroll = $('.st-content').scrollTop() > 100;
    },
  },
};
</script>

<style>
  .Orderdetails_area.fix {
    top: 100px;
    position: sticky;
  }
</style>
